﻿import { Component } from '@angular/core';
import { DBStateComponent } from 'src/app/build-a-door/shared/components/db-state.component';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { Router } from '@angular/router';
import { DBFooterService } from 'src/app/shared/services/db-footer.service';
import { ProductService } from '../../../shared/services';
import { DoorStyle } from '../../../shared/models';

@Component({
    selector: 'app-design-home',
    templateUrl: './design-home.component.html',
    styleUrls: ['./design-home.component.css'],
})
export class DBDesignHomeComponent extends DBStateComponent {
    constructor(public dbService: BuildADoorService, public router: Router, public dbFooterService: DBFooterService, private productService: ProductService) {
        super(dbService, router, dbFooterService);
    }

    private readonly COLLECTIONS_SUBSTATE = 'COLLECTIONS';
    private readonly MODELS_SUBSTATE = 'MODELS';
    private readonly DESIGNS_SUBSTATE = 'DESIGNS';
    private readonly INSULATION_SUBSTATE = 'INSULATION';
    private readonly WINDOWS_SUBSTATE = 'WINDOWS';
    private readonly COLORS_SUBSTATE = 'COLORS';

    private activeStates: Object = {};

    async ngOnInit() {
        //super.verifyState();
        if (!this.dbService.customerDesignedDoor.width || !this.dbService.customerDesignedDoor.height || !this.dbService.customerDesignedDoor.deliveryOption || !this.dbService.customerDesignedDoor.isWindSpeedAnswered) {
            this.router.navigate(['/door-builder/setup/select-size']);
        } else if (!this.dbService.selectedStyle) {
            if (this.dbService.customerDesignedDoor.productGroup) {
                this.dbService.selectedStyle = this.dbService.customerDesignedDoor.productGroup.style;
                this.productService.getDoorDesignsForProductGroup(this.dbService.getPricesQuery()).subscribe(result => {
                    if (result) {
                        if (result['doorDesigns']) {
                            this.dbService.customerDesignedDoor.doorDesign = this.dbService.initializeDoorDesign(result['doorDesigns'][0]);
                            this.dbService.setDoorDesignSelections(result['doorDesigns']);
                            if (result['doorDesigns'].length === 1) {
                                this.dbService.selectedProductGroup.doorDesign = result['doorDesigns'][0];
                            }
                        }
                    }
                });
            } else {
                this.router.navigate(['/door-builder/design/select-collection']);
            }
        } else if (!this.dbService.customerDesignedDoor.productGroup) {
            this.router.navigate(['/door-builder/design/select-model']);
        } else if (!this.dbService.isHidePanelDesigns() && !this.dbService.customerDesignedDoor.doorDesign) {
            this.router.navigate(['/door-builder/design/panel-design']);
        }


        this.activeStates[this.COLLECTIONS_SUBSTATE] = true;
        this.activeStates[this.MODELS_SUBSTATE] = true;
        this.activeStates[this.DESIGNS_SUBSTATE] = !this.dbService.isHidePanelDesigns();
        this.activeStates[this.INSULATION_SUBSTATE] = !this.dbService.isHideInsulation();
        this.activeStates[this.WINDOWS_SUBSTATE] = !this.dbService.isHideWindows();
        this.activeStates[this.COLORS_SUBSTATE] = true;

        this.dbFooterService.setDesignState();
        this.dbFooterService.completeState(this.dbFooterService.SETUP_STATE);
        this.dbFooterService.updateParentState(this.dbFooterService.SETUP_POSITION);
        //this.dbFooterService.totalStates = 6;
        this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumDesignStepsComplete());
        super.ngOnInit();
    }

    getInsulationName() {
        let name = '';
        if (this.dbService.customerDesignedDoor.insulation) {
            name = this.dbService.customerDesignedDoor.insulation.name;
            if (this.dbService.customerDesignedDoor.insulation.price) {
                name += ' ($' + this.dbService.getDisplayPrice(this.dbService.customerDesignedDoor.insulation.price) + ')';
            }
        }
        return name;
    }

    getWindowDescription() {
        let desc = '';
        if (this.dbService.customerDesignedDoor.window) {
            desc = this.dbService.customerDesignedDoor.window.name + ' ';
            if (this.dbService.customerDesignedDoor.glassType)
                desc += this.dbService.customerDesignedDoor.glassType.name
            else
                desc += this.dbService.customerDesignedDoor.window.markupType;
            let price = this.dbService.customerDesignedDoor.getWindowPrice();
            desc += this.dbService.getPriceDisplay(price);
            //if (price > 0) {
            //    desc += ' Upgrade $' + this.dbService.getPriceDisplay(price);
            //}
        }
        return desc;
    }

    getExtrasDescription() {
        let desc = '';
        let exTotal = 0;
        if (this.dbService.customerDesignedDoor.extras && this.dbService.customerDesignedDoor.extras.length > 0) {
            for (var i = 0; i < this.dbService.customerDesignedDoor.extras.length; i++) {
                if (i > 0) desc += ', ';
                let ex = this.dbService.customerDesignedDoor.extras[i];
                desc += ex.name;
                if (ex.description) {
                    desc += ' (' + ex.description + ')';
                }
                exTotal += ex.price;
            }
        }
        if (this.dbService.customerDesignedDoor.opener) {
            desc += this.dbService.customerDesignedDoor.opener.name;
            if (this.dbService.customerDesignedDoor.opener.description) {
                desc += ' (' + this.dbService.customerDesignedDoor.opener.description + ')';
            }
            exTotal += this.dbService.customerDesignedDoor.opener.price;
        }
        if (exTotal > 0)
            desc += ' ($' + this.dbService.getPriceDisplayNumericOnly(exTotal) + ')';
        return desc ? desc : 'NONE';
    }

    onClickContinue() {
        if (this.isShowContinue()) {
            super.nextState(this.dbService.customerDesignedDoor.getNumDesignStepsComplete());
        }
    }

    isHideSubstate(substate, compareField) {
        //if (this.dbService[compareField]) {
        //    this.activeStates[substate] = false;
        //}

        let numCurrentlyActive = Object.values(this.activeStates).filter(o => o === true).length;
        if (numCurrentlyActive !== this.dbFooterService.totalStates) {
            this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumDesignStepsComplete());
        }

        return this.activeStates[substate];
    }

    isShowContinue() {
        if (this.dbService.customerDesignedDoor.productGroup && this.dbService.customerDesignedDoor.doorDesign &&
            this.dbService.customerDesignedDoor.insulation && this.dbService.customerDesignedDoor.window &&
            this.dbService.customerDesignedDoor.color) {
            this.dbFooterService.message = 'Click Continue to Select Your Track Options';
            return true;
        }
        return false;
    }
}