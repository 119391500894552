﻿import { Component, ChangeDetectorRef } from '@angular/core';
import { BuildADoorService, DBFooterService } from 'src/app/shared/services';
import { DBStateComponent } from '../shared/components/db-state.component';
import { ProductGroup } from 'src/app/shared/models';
import { Router, Route, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
    selector: 'app-db-design',
    templateUrl: './db-design.component.html',
    styleUrls: ['./db-design.component.css'],
})
export class DBDesignComponent extends DBStateComponent {
    showsTooltip = false; // show or not
    tooltipTopY!: any;
    tooltipLeftX!: any;
    tooltipText: string;

    constructor(public dbService: BuildADoorService, public router: Router, public dbFooterService: DBFooterService, private changeDetector: ChangeDetectorRef) {
       super(dbService, router, dbFooterService);
       this.routeSub = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                //this.printpath('', this.router.config);
                if (event.url === '/door-builder/design') {
                    if (this.dbService.isDesignState())
                        this.router.navigate(['/door-builder/design/design-home']);
                    else if (this.dbService.isExtrasState())
                        this.router.navigate(['/door-builder/design/extras-home']);
                    //else if (this.dbService.isHardwareState())
                    //    this.router.navigate(['/door-builder/design/select-hardware']);
                }
            }
        });
     }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }

    ngOnDestroy() {
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }
    }

    getTotalPrice() {
        return this.dbService.getTotalPriceDisplay();
    }

    getTotalPriceDisplay() {
        if (this.dbService.getAppliedPromotion()) {
            return '$' + this.dbService.getTotalPriceDisplay();
        }
        return '';
    }

    getAppliedPrice() {
        if (this.dbService.getAppliedPromotion())
            return this.dbService.getAppliedPriceDisplay();
        return this.getTotalPrice();
    }

    printpath(parent: String, config: Route[]) {
        for (let i = 0; i < config.length; i++) {
            const route = config[i];
            console.log(parent + '/' + route.path);
            if (route.children) {
                const currentPath = route.path ? parent + '/' + route.path : parent;
                this.printpath(currentPath, route.children);
            }
        }
    }

    getImageClass() {
        if (this.dbService.customerDesignedDoor.getImage()) {
            return 'DBImage';
        }
        return '';
    }

    getDimensionsDisplay() {
        let dims = this.dbService.customerDesignedDoor.width.feet + "'";
        if (this.dbService.customerDesignedDoor.width.inches) {
            dims += this.dbService.customerDesignedDoor.width.inches + '"';
        }
        dims += " x ";
        dims += this.dbService.customerDesignedDoor.height.feet + "'";
        if (this.dbService.customerDesignedDoor.height.inches) {
            dims += this.dbService.customerDesignedDoor.height.inches + '"';
        }
        return dims;
    }

    getWindLoadDisplay() {
        let windLoad = this.dbService.customerDesignedDoor.windSpeed;
        if (windLoad) {
            windLoad += ' MPH '
        } else {
            windLoad = 'No '
        }
        windLoad += ' Wind Load';
        return windLoad;
    }

    onDimensionsClick() {
        super.moveStates(this.dbService.designState, this.dbService.setupDoorSizeState);
    }

    onWindLoadClick() {
        super.moveStates(this.dbService.designState, this.dbService.setupWindRatingState);
    }

    onDeliveryClick() {
        super.moveStates(this.dbService.designState, this.dbService.setupState);
    }

    onImageClick(event) {
        if (!this.dbService.isCustomWindows) return;
        if (!event) return;
        console.log("event = " + event);
        let door = this.dbService.customerDesignedDoor;
        //let widthSlots = door.getWidthSlots();
        //let heightSlots = door.getHeightSlots();
        let image = event.target;
        if (!image) return;

        //let sectionWidth = image.width / widthSlots;
        //let sectionHeight = image.height / heightSlots;
        //var targetPanel = Math.floor(event.offsetX / sectionWidth);
        //var targetSection = Math.floor(event.offsetY / sectionHeight);
        if (typeof this.dbService.customerDesignedDoor.window.panelType !== 'undefined' &&
            this.dbService.customerDesignedDoor.window.panelType !== null) {
            if (this.dbService.customerDesignedDoor.window.panelType === 'Long') {
                this.dbService.customerDesignedDoor.doorDesign.isLongPanel = true;
            } else {
                this.dbService.customerDesignedDoor.doorDesign.isLongPanel = false;
            }
        }
        let targetPanel = this.calculateWidthPanel(image, event.offsetX);
        let targetSection = this.calculateHeightPanel(image, event.offsetY);
        door.windowPositions[targetPanel][targetSection] = !door.windowPositions[targetPanel][targetSection];
        if (!this.isMobile()) {
            if (door.windowPositions[targetPanel][targetSection]) this.tooltipText = "Click to <b>remove</b> window.";
            else this.tooltipText = "Click to <b>add</b> window.";
        }
    }

    calculateWidthPanel(image: any, offset: number): number {
        return this.calculatePanel(image, offset, this.dbService.customerDesignedDoor.getWidthSlots(), image.width);
    }

    calculateHeightPanel(image: any, offset: number): number {
        return this.calculatePanel(image, offset, this.dbService.customerDesignedDoor.getHeightSlots(), image.height);
    }

    calculatePanel(image: any, offset: number, numSlots: number, dimension: number): number {
        let sectionSize = dimension / numSlots;
        var targetPanel = Math.floor(offset / sectionSize);
        return targetPanel;
    }

    onHover(event: any): void {
        if (event) {
            this.showsTooltip = true;
            this.tooltipTopY = (event.clientY + 20) + 'px';
            this.tooltipLeftX = (event.clientX + 20) + 'px';

            let door = this.dbService.customerDesignedDoor;
            let image = event.target;
            if (!image) return;
            let targetPanel = this.calculateWidthPanel(image, event.offsetX);
            let targetSection = this.calculateHeightPanel(image, event.offsetY);
            if (door.windowPositions && door.windowPositions[targetPanel] && door.windowPositions[targetPanel][targetSection]) {
                this.tooltipText = "Click to <b>remove</b> window.";
            } else {
                this.tooltipText = "Click to <b>add</b> window.";
            }
        }
    }

    getTooltipStyle(): Object {
        if (this.isMobile()) {
            this.tooltipText = "Click on a panel to add or remove a window in that position";
            return {}
        } else {
            return {
                'position': 'fixed',
                'top': this.tooltipTopY,
                'left': this.tooltipLeftX,
                'overflow': 'hidden'
            };
        }
    }
}