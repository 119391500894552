import { WidthBasedPricing } from './width-based-pricing';
import { GlassType } from './glass-type';

export class DoorWindow {
    _id: string;
    name: string;
    code: string;
    imageURL: string;
    markupType: string;
    transparency: string;
    nameInURL: string;
    imageName: string;
    panelType: string;
    price: number;
    widthBasedPricing: WidthBasedPricing[];
    isPricePerLinearFoot: boolean;
    isPricePerSquareFoot: boolean;
    isInsulated: boolean;
    insulatedPrice: number;
    insulatedSelected: boolean;
    glassTypes: GlassType[];
    glassTypeSelected: boolean;
    displayOrder: number;
    isCustom: boolean;
    isDefaultMosaic: boolean;
    isTotalSlotsPricing: boolean;

    constructor(w?) {
        if (w) {
            this._id = w._id;
            this.name = w.name;
            this.code = w.code;
            this.imageURL = w.imageURL;
            this.markupType = w.markupType;
            this.transparency = w.transparency;
            this.nameInURL = w.nameInURL;
            this.imageName = w.imageName;
            this.panelType = w.panelType;
            this.price = w.price;
            this.isPricePerLinearFoot = w.isPricePerLinearFoot;
            this.isPricePerSquareFoot = w.isPricePerSquareFoot;
            this.isInsulated = w.isInsulated;
            this.insulatedPrice = w.insulatedPrice;
            this.insulatedSelected = w.insulatedSelected;
            this.glassTypes = w.glassTypes;
            this.glassTypeSelected = w.glassTypeSelected;
            this.displayOrder = w.displayOrder;
            this.isCustom = w.isCustom;
            this.isDefaultMosaic = w.isDefaultMosaic;
            this.isTotalSlotsPricing = w.isTotalSlotsPricing;
        }
    }

}
