﻿import { Component } from '@angular/core';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { ProductGroup, DoorDesign } from 'src/app/shared/models/index';
import { ProductService } from 'src/app/shared/services/product.service';
import { DBStateComponent } from '../../shared/components/db-state.component';
import { Router } from '@angular/router';
import { DBFooterService } from 'src/app/shared/services/db-footer.service';

@Component({
    selector: 'app-select-model',
    templateUrl: './model.component.html',
    styleUrls: ['./model.component.css'],
})
export class DBDesignModelComponent extends DBStateComponent {
    private productGroups: Object[];

    constructor(public dbService: BuildADoorService,
        public dbFooterService: DBFooterService,
        public router: Router,
        private productService: ProductService) {
        super(dbService, router, dbFooterService);
    }

    async ngOnInit() {
        super.verifyState();
        //if (!this.dbService.selectedStyle) {
        //    this.router.navigate(['/door-builder/design/design-home']);
        //}

        this.dbService.getProductGroups().subscribe(result => {
            if (result) {
                if (result['productGroups']) {
                    this.productGroups = result['productGroups'];
                    this.productGroups.sort((a, b) => Number(a['minBasePrice']) > Number(b['minBasePrice']) ? 1 : (Number(b['minBasePrice']) > Number(a['minBasePrice']) ? -1 : 0));
                    //let heightInches = (this.dbService.customerDesignedDoor.height.feet * 12);
                    //if (this.dbService.customerDesignedDoor.height.inches) {
                    //    heightInches += this.dbService.customerDesignedDoor.height.inches;
                    //}
                    //let maxIter = (heightInches / 28) + 1;
                    //let isFitsClassica = false;
                    //for (var i = 0; i < maxIter; i++) {
                    //    let remainder = heightInches - (32 * i);
                    //    if (remainder % 28 === 0) {
                    //        isFitsClassica = true;
                    //        break;
                    //    }
                    //    remainder = heightInches - (28 * i);
                    //    if (remainder % 32 === 0) {
                    //        isFitsClassica = true;
                    //        break;
                    //    }
                    //}
                    //if (!isFitsClassica) {
                    //    this.productGroups = this.productGroups.filter(pg => pg['productGroup']['name'] !== 'Classica');
                    //}
                    //if (heightInches % 28 !== 0 && heightInches % 32 !== 0) {
                    //    // filter out classica
                    //    // TODO - make this happen any time we enter the screen / after a size change
                    //    this.productGroups = this.productGroups.filter(pg => pg['productGroup']['name'] !== 'Classica');
                    //}
                    this.dbService.setProductGroupSelection(this.productGroups);
                    this.setCollectionWrapperStyling(this.productGroups, true);
                }
            }
        });

        super.ngOnInit();
    }

    getCollectionStyle(index) {
        let style = super.collectionStyleNoColumns(index, true);
        //if (!this.isMobile()) {
        //    var colNum = 1 + (index % 2);
        //    style['grid-column'] = '' + colNum;
        //}
        return style;
    }

    getCollectionClass(pg) {
        return super.collectionClass(this.dbService.customerDesignedDoor.productGroup, pg);
    }

    onClickProductGroup(pg) {
        if (pg.productGroup != this.dbService.customerDesignedDoor.productGroup) {
            this.dbService.removeSelections();
        }

        this.dbService.customerDesignedDoor.productGroup = pg.productGroup;
        this.dbService.selectedProductGroup = pg;
        this.dbService.customerDesignedDoor.windLoadPrice = pg.windLoadPrice;
        this.dbService.price = pg.basePrice;
        this.dbService.customerDesignedDoor.basePrice = pg.basePrice;

        if (pg.insulationOption) {
            this.dbService.customerDesignedDoor.insulation = pg.insulationOption;
        //    this.dbService.isHideInsulation = true;
        //} else {
        //    this.dbService.isHideInsulation = false;
        }
        if (pg.doorDesign) {
            this.dbService.customerDesignedDoor.doorDesign = new DoorDesign(pg.doorDesign);
            //this.productService.getDoorDesignsForProductGroup(this.dbService.getPricesQuery()).subscribe(result => {
            //    if (result) {
            //        if (result['doorDesigns']) {
            //            this.dbService.customerDesignedDoor.doorDesign = this.dbService.initializeDoorDesign(result['doorDesigns'][0]);
            //            this.dbService.setDoorDesignSelections(result['doorDesigns']);
            //        }
            //    }
            //});

            if (this.dbService.customerDesignedDoor.doorDesign.windows.length === 1 && this.dbService.customerDesignedDoor.productGroup.identifier !== 'ClassicaFullView') {
                //this.dbService.isHideWindows = true;
                this.dbService.customerDesignedDoor.window = this.dbService.customerDesignedDoor.doorDesign.windows[0];
            }
        //    this.dbService.isHidePanelDesign = true;
        //} else {
        //    this.dbService.isHidePanelDesign = false;
        }
        this.dbService.applySpecials();
        this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumDesignStepsComplete());
        this.router.navigate(['/door-builder/design/design-home'])
    }

    onClickBack() {
        this.dbService.navButtonPressed = true;
        this.router.navigate(['/door-builder/design/select-collection']);
    }
}