﻿import { ProductGroup } from './product-group';
import { Dimension } from './dimension';
import { DoorDesign } from './door-design';
import { DoorInsulationOption } from './door-insulation-option';
import { DoorWindow } from './door-window';
import { DoorColor } from './door-color';
import { DoorHardware } from './door-hardware';
import { DoorOpener } from './door-opener';
import { DoorExtra } from './door-extra';
import { CartItem } from 'ng-shopping-cart';
import { BuildingZone } from './building-zone';
import { Headroom } from './headroom';
import { TrackAndSpring } from './track-and-spring';
import { TrackMountingOption } from './track-mounting-option';
import { SpringWindingOption } from './spring-winding-option';
import { DoorLock } from './door-lock';
import { GlassType } from './glass-type';
import { v4 as uuidv4 } from 'uuid';
import { AddOn } from './add-on';
import { Discount } from './discount';
import { DoorSpecial } from '.';
import { WidthBasedPricing } from './width-based-pricing';

export class CustomerDesignedDoor extends CartItem {
    zone: BuildingZone;
    productGroup: ProductGroup;
    width: Dimension = new Dimension();
    height: Dimension = new Dimension();
    windSpeed: string = '';
    windLoadPrice: number;
    impactType: string = '';
    deliveryOption: string = 'deliverOnly';
    doorDesign: DoorDesign;
    insulation: DoorInsulationOption;
    window: DoorWindow;
    glassType: GlassType;
    color: DoorColor;
    childColor: DoorColor;
    hardware: DoorHardware[] = [];
    locks: DoorLock[] = [];
    opener: DoorOpener;
    needsOperatorInstall: boolean;
    extras: DoorExtra[] = [];
    headroom: Headroom;
    trackAndSpring: TrackAndSpring;
    trackMountingOption: TrackMountingOption;
    springWindingOption: SpringWindingOption;
    appliedPromotion: DoorSpecial;
    isWindSpeedAnswered: boolean = false;
    totalPrice: any;
    imageURL: string;
    public minWidth: number = -1;
    public minHeight: number = -1;
    uuid: string;
    public readonly COLOR_ZONE_NAME = "Use Color Zone";
    quantity: number = 1;
    isClassicaWarning: boolean = false;
    addOns: AddOn[] = [];
    discount: Discount[] = [];
    tax: number;
    basePrice: number;
    price: number;
    windowPositions: boolean[][];
    public readonly CLEAR_MARKUP = 'Clear';
    public readonly DECRAGLASS_MARKUP = 'Decraglass';
    public readonly DECRATRIM_MARKUP = 'Decratrim';
    public readonly CLOSED_MARKUP = 'Closed';
    public readonly LEFT_MOSAIC = 'Left Mosaic';
    public readonly RIGHT_MOSAIC = 'Right Mosaic';
    public readonly CUSTOM_WINDOWS = 'Custom';
    public readonly STANDARD_WINDOWS = 'Standard';
    hasCustomWindows: boolean;

    constructor(itemData?: any) {
        super();
        if (itemData) {
            this.zone = itemData.zone;
            this.productGroup = itemData.productGroup;
            this.width = itemData.width;
            this.height = itemData.height;
            this.windSpeed = itemData.windSpeed;
            this.windLoadPrice = itemData.windLoadPrice;
            this.impactType = itemData.impactType;
            this.deliveryOption = itemData.deliveryOption;
            this.doorDesign = itemData.doorDesign;
            this.window = itemData.window;
            this.insulation = itemData.insulation;
            this.glassType = itemData.glassType;
            this.color = itemData.color;
            this.childColor = itemData.childColor;
            this.hardware = itemData.hardware;
            this.locks = itemData.locks;
            this.opener = itemData.opener;
            this.extras = itemData.extras;
            this.headroom = itemData.headroom;
            this.trackAndSpring = itemData.trackAndSpring;
            this.trackMountingOption = itemData.trackMountingOption;
            this.springWindingOption = itemData.springWindingOption;
            this.isWindSpeedAnswered = itemData.isWindSpeedAnswered;
            this.totalPrice = itemData.totalPrice;
            this.imageURL = itemData.imageURL;
            this.minWidth = itemData.minWidth;
            this.minHeight = itemData.minHeight;
            this.quantity = itemData.quantity ? itemData.quantity : 1;
            this.isClassicaWarning = false;
            this.addOns = itemData.addOns;
            this.discount = itemData.discount || [];
            this.tax = itemData.tax || 0.00;
            this.basePrice = itemData.basePrice;
            this.price = itemData.price;
            this.appliedPromotion = itemData.appliedPromotion;
            this.windowPositions = itemData.windowPositions;
            this.hasCustomWindows = itemData.hasCustomWindows;
        } else {
            this.price = 0;
        }

        this.uuid = uuidv4();
    } 

    getNumDesignStepsComplete(): number {
        let numComplete = 0;
        if (this.productGroup) numComplete++;
        if (this.doorDesign) numComplete++;
        if (this.insulation) numComplete++;
        if (this.window) numComplete++;
        if (this.color) numComplete++;
        //if (this.hardware && this.hardware.length > 0) numComplete++;
        return numComplete;
    }

    getNumSetupStepsComplete(): number {
        let numComplete = 0;
        if (this.deliveryOption === 'install' || this.deliveryOption === 'deliverOnly') numComplete++;
        if (this.width.feet > 0 && this.height.feet > 0) numComplete++;
        if (this.isWindSpeedAnswered) numComplete++;
        return numComplete;
    }

    public getId(): any {
        return this.productGroup ? this.uuid : null;
    }

    public setId(id: string): void {
        this.uuid = id;
    }

    public getName(): string {
        let name = null;
        if (this.insulation) name = this.insulation.name;
        else if (this.productGroup) name = this.productGroup.display_name;
        if (this.doorDesign) name += ' - ' + this.doorDesign.name;
        if (this.width && this.height) {
            name += ' - '
            name += this.width.feet + '\'';
            if (this.width.inches) name += this.width.inches + '\"';
            name += ' x ';
            name += this.height.feet + '\'';
            if (this.height.inches) name += this.height.inches + '\"';
        }
        if (this.windSpeed) {
            name += ' - ' + this.windSpeed + ' MPH';
            if (this.impactType) name += ' ' + this.impactType;
        }
        if (this.color) name += ' - ' + this.color.name;
        if (this.window) {
            name += ' - ' + this.window.name;
            if (this.glassType) {
                name += ' ';
                name += this.glassType.name;
            } else if (this.window.isInsulated) {
                name += ' Insulated';
            }
        }
        if (this.headroom) name += ' - ' + this.headroom.name;
        if (this.trackAndSpring) name += ' - ' + this.trackAndSpring.name;
        if (this.springWindingOption) name += ' - ' + this.springWindingOption.name;
        if (this.hardware) name += this.addCollectionItems('Hardware', this.hardware);
        if (this.extras) name += this.addCollectionItems('Extras', this.extras);
        if (this.opener) name += ' - ' + this.opener.name;
        if (this.locks) name += this.addCollectionItems('Locks', this.locks);
        return name;
    }

    private addCollectionItems(itemLabel, itemCollection): string {
        if (itemCollection && itemCollection.length > 0) {
            let collectionItems = ' - ' + itemLabel + ': ';
            for (var i = 0; i < itemCollection.length; i++) {
                if (i > 0) collectionItems += ', ';
                collectionItems += itemCollection[i].quantity ? '' + itemCollection[i].quantity + ' ' + itemCollection[i].name : itemCollection[i].name;
            }
            return collectionItems;
        }
        return '';
    }

    public getPrice(): any {
        //if (this.discount) {
        //    if (this.discount.amount) return this.totalPrice - this.discount.amount;
        //    if (this.discount.percent) return this.totalPrice * (100.0 - this.discount.percent);
        //}
        return parseFloat(this.totalPrice);
    }

    public setPrice(price: any) {
        this.totalPrice = price;
    }

    public getNearestClassicaImageHeight(): number {
        if (!this.height) return 0;
        if (!this.productGroup) return 0;
        let heightInches = (this.height.feet * 12);
        if (this.height.inches) {
            heightInches += this.height.inches;
        }
        let maxIter = (heightInches / 28) + 1;
        let minDistance = 999;
        let nearestHeight = 0;
        for (var i = 0; i < maxIter; i++) {
            let remainder = heightInches - (32 * i);
            if (remainder >= 0) {
                let mod = remainder % 28;
                if (mod === 0) {
                    nearestHeight = heightInches;
                    break;
                } else if (mod < minDistance) {
                    minDistance = mod;
                    nearestHeight = heightInches - minDistance;
                }
            }
            remainder = heightInches - (28 * i);
            if (remainder >= 0) {
                let mod = remainder % 32;
                if (mod === 0) {
                    nearestHeight = heightInches;
                    break;
                } else if (mod < minDistance) {
                    minDistance = mod;
                    nearestHeight = heightInches - minDistance;
                }
            }
        }

        return nearestHeight;
    }

    getCalculatedWidth(): number {
        if (!this.width || !this.productGroup) return 0;
        let w = this.width.feet;
        if (this.width.inches) w++;
        if (this.minWidth && w < this.minWidth) w = this.minWidth;
        return w;
    }

    getWidthSlots(): number {
        let doorDesign = this.doorDesign;
        if (!doorDesign) return 0;
        let w = this.getCalculatedWidth();

        let widthSlots = 4;
        if (doorDesign.isLongPanel) {
            if (w >= 20) {
                widthSlots = 5;
            } else if (w >= 15) {
                widthSlots = 4;
            } else if (w >= 12) {
                widthSlots = 3;
            } else {
                widthSlots = 2;
            }
        } else {
            if (w >= 20) {
                widthSlots = 10;
            } else if (w >= 16) {
                widthSlots = 8;
            } else if (w >= 12) {
                widthSlots = 6;
            } else if (w == 10) {
                widthSlots = 5;
            } else {
                widthSlots = 4;
            }
        }
        return widthSlots;
    }

    getCalculatedHeight(): number {
        if (!this.height || !this.productGroup) return 0;
        let h = this.height.feet;
        if (this.height.inches) h++;
        if (this.minHeight && h < this.minHeight) h = this.minHeight;
        if (this.productGroup.name !== 'Classica') {
            if (h === 11) h = 12;
            if (h === 13) h = 14;
        }
        return h;
    }

    getHeightSlots(): number {
        let doorDesign = this.doorDesign;
        if (!doorDesign) return 0;

        let h = this.getCalculatedHeight();
        let heightSlots = 4;
        if (h >= 14) {
            heightSlots = 8;
        } else if (h >= 12) {
            heightSlots = 7;
        } else if (h > 8) {
            heightSlots = 6;
        } else if (h > 7) {
            heightSlots = 5;
        } else {
            heightSlots = 4;
        }
        return heightSlots;
    }

    setWindowLocations(windowPositionType?: string): void {
        let doorDesign = this.doorDesign;
        if (!doorDesign) return;

        let window = this.window;
        if (!window) window = doorDesign.windows[0];

        //let prevIsLongPanel = this.doorDesign.isLongPanel;
        if (typeof window.panelType !== 'undefined' && window.panelType !== null) {
            if (window.panelType === 'Long') {
                this.doorDesign.isLongPanel = true;
            } else {
                this.doorDesign.isLongPanel = false;
            }
        }

        let heightSlots = this.getHeightSlots();
        let widthSlots = this.getWidthSlots();

        //if (!this.windowPositions || this.windowPositions.length === 0 || prevIsLongPanel !== this.doorDesign.isLongPanel) {
        if (!this.windowPositions || this.windowPositions.length === 0) {
            this.windowPositions = Array.from(Array(widthSlots), () => new Array(heightSlots).fill(false));
        }

        let columnNum = windowPositionType === this.LEFT_MOSAIC ? 0 : windowPositionType === this.RIGHT_MOSAIC ? widthSlots - 1 : 0;
        for (let i = 0; i < heightSlots; i++) {
            for (let j = 0; j < widthSlots; j++) {
                if (window && window.name != doorDesign.windows[0].name &&
                    (((!windowPositionType || windowPositionType === this.STANDARD_WINDOWS) && i === 0) || ((windowPositionType === this.LEFT_MOSAIC || windowPositionType === this.RIGHT_MOSAIC) && j === columnNum))) {
                    if (!this.windowPositions || typeof this.windowPositions[j] === 'undefined') {
                        console.log("stop here");
                    }
                    this.windowPositions[j][i] = true;
                } else {
                    this.windowPositions[j][i] = false;
                }
            }
        }
        this.imageURL = null;
        this.getImage();
    }

    public getImage(): string {
        if (!this.doorDesign || !this.doorDesign.prepopulatedImageUrl || !this.doorDesign.code) return '';
        //if (this.imageURL) return this.imageURL;
        let doorDesign = this.doorDesign;
        if (!doorDesign) return '';
        let color = this.childColor ? this.childColor : this.color;
        if (!color || color.name === this.COLOR_ZONE_NAME || color.isColorZone) {
            if (doorDesign.getColors) {
                color = doorDesign.getColors()[0];
            } else {
                color.code = "TW";
                color.name = "True White";
            }
        }
        let window = this.window;
        if (!window) window = doorDesign.windows[0];
        let insulation = this.insulation;
        let insulationLevel = this.productGroup.abbreviation === 'nwood' ? { name: '3000' } : { name: '1000' };
        if (insulation) {
            insulationLevel = insulation.insulationLevel;
            if (!insulationLevel) insulationLevel = { name: '1000' };
        }
        if (!color || !doorDesign || !window) return '';
        // TODO - hardware and codes
        let imageSource = doorDesign.prepopulatedImageUrl;
        if (imageSource.indexOf('%')) {
            let w = this.getCalculatedWidth();
            let h = this.getCalculatedHeight();
            //let w = this.width.feet;
            //if (this.width.inches) w++;
            //if (w < this.minWidth) w = this.minWidth;
            //let h = this.height.feet;
            //if (this.height.inches) h++;
            //if (h < this.minHeight) h = this.minHeight;
            //if (this.productGroup.name !== 'Classica') {
            //    if (h === 11) h = 12;
            //    if (h === 13) h = 14;
            //}
            let wDim = w * 12;
            let hDim = h * 12;
            if (this.productGroup.name === 'Classica') {
                hDim = this.getNearestClassicaImageHeight();
            }
            let dimensions = wDim + 'x' + hDim;

            let glassType = this.glassType;

            imageSource = imageSource.replace('%DIMENSIONS%', dimensions);
            imageSource = imageSource.replace('%WIN_NAME%', window.nameInURL ? window.nameInURL : window.name);
            imageSource = imageSource.replace('%ABBREVIATION%', this.productGroup.abbreviation);
            imageSource = imageSource.replace('%INSULATION_LEVEL%', insulationLevel.name);
            imageSource = imageSource.replace('%COLOR_CODE%', color.code);
            imageSource = imageSource.replace('%COLOR_NAME%', color.name.replace(' ', '_'));
            imageSource = imageSource.replace('%TRANSPARENCY%', glassType && glassType.code ? glassType.code.replace(/\s/g, '') : window.transparency);
            imageSource = imageSource.replace('%IMAGE_NAME%', window.imageName);
            imageSource = imageSource.replace('%PANEL_TYPE%', window.panelType);
            if (window.panelType === "Long") this.doorDesign.isLongPanel = true;
            else if (window.panelType === "Short") this.doorDesign.isLongPanel = false;

            let isDoubleWidth = false;
            if (w >= 16) isDoubleWidth = true;
            imageSource = imageSource.replace('%SINGLE_DOUBLE%', isDoubleWidth ? 'double' : 'single');
            imageSource = imageSource.replace('%DOUBLE%', isDoubleWidth ? 'double/' : '');
            let slots = '';
            //let widthSlots = 4;
            //let heightSlots = 4;
            let heightOnlySlots = 4;
            //            if (doorDesign.name.startsWith('Long')) {
            //if (doorDesign.isLongPanel) {
            //    if (w >= 20) {
            //        widthSlots = 5;
            //    } else if (w >= 15) {
            //        widthSlots = 4;
            //    } else if (w >= 12) {
            //        widthSlots = 3;
            //    } else {
            //        widthSlots = 2;
            //    }
            //} else {
            //    if (w >= 20) {
            //        widthSlots = 10;
            //    } else if (w >= 16) {
            //        widthSlots = 8;
            //    } else if (w >= 12) {
            //        widthSlots = 6;
            //    } else if (w == 10) {
            //        widthSlots = 5;
            //    } else {
            //        widthSlots = 4;
            //    }
            //}
            //if (h >= 14) {
            //    heightSlots = 8;
            //} else if (h >= 12) {
            //    heightSlots = 7;
            //} else if (h > 8) {
            //    heightSlots = 6;
            //} else if (h > 7) {
            //    heightSlots = 5;
            //} else {
            //    heightSlots = 4;
            //}
            let heightSlots = this.getHeightSlots();
            let widthSlots = this.getWidthSlots();


            let windowPortion = this.imageURL ? this.urlHasCustomWindows(this.imageURL) : null;
            if (windowPortion) { 
                slots = windowPortion;
            } else {
                if (!this.windowPositions || this.windowPositions.length != widthSlots || this.windowPositions[0].length != heightSlots) {
                    this.windowPositions = Array.from(Array(widthSlots), () => new Array(heightSlots).fill(false));
                    this.setWindowLocations();
                }

                for (let i = 0; i < heightSlots; i++) {
                    for (let j = 0; j < widthSlots; j++) {
                        if (this.windowPositions[j][i]) {
                            //if (window && window.name != doorDesign.windows[0].name && i === 0) {
                            slots += 'G';
                        } else {
                            slots += 'X';
                        }
                    }
                    if (i < heightSlots - 1) {
                        slots += '-';
                    }
                }
            }
            imageSource = imageSource.replace('%SLOTS%', slots);

            heightOnlySlots = this.getHeightOnlySlots(h);
            let hSlots = '';
            for (let i = 0; i < heightOnlySlots; i++) {
                hSlots += 'G';
            }
            imageSource = imageSource.replace('%SLOTS_HEIGHT_ONLY%', hSlots);
        } else {
            imageSource += color.code + '/' + doorDesign.code + '_' + window.code + '_' + color.code + '_S.jpg'
        }
        return imageSource;
    }

    urlHasCustomWindows(url: string) {
        let windowPortion = url.substring(url.lastIndexOf('/'), url.indexOf('.jpg'));
        if (windowPortion) {
            let heightSlots = this.getHeightSlots();
            let widthSlots = this.getWidthSlots();

            if (windowPortion.indexOf('-') >= 0) {
                let rows = windowPortion.split('-');

                let hasCustom = false;
                for (let i = 1; i < heightSlots; i++) {
                    let row = rows[i];
                    for (let j = 0; j < widthSlots; j++) {
                        if (row.charAt(j) === 'G') {
                            hasCustom = true;
                            break;
                        }
                    }
                }

                if (hasCustom || rows[0].indexOf('G') < 0) return windowPortion;
            }
        }
    }

    public getClassicaFullViewHeightOnlySlots(h: number): number {
        if (h > 12) {
            return 6;
        }
        if (h > 10) {
            return 5;
        }
        if (h > 8) {
            return 4;
        }
        return 3;
    }

    public getClassicaFullViewWidthSlots(w: number): number {
        if (w > 15) {
            return 4;
        }
        if (w > 10) {
            return 3;
        }
        return 2;
    }

    public getHeightOnlySlots(h: number): number {
        if (h > 12) {
            return 7;
        }
        if (h > 10) {
            return 6;
        }
        if (h > 8) {
            return 5;
        }
        return 4;
    }

    public getQuantity(): any {
        return this.quantity;
    }

    public setQuantity(quantity: number): void {
        this.quantity = quantity;
    }

    public addAddOn(a: AddOn): void {
        this.addOns.push(a);
    }

    public addDiscount(d: Discount): void {
        this.discount.push(d);
    }

    public setTax(t: number): void {
        this.tax = t;
    }

    public getShipping(): number {
        if (this.getQuantity() > 0) {
            if (this.getQuantity() > 2) return 350.00 + (175.00 * (this.getQuantity() - 2));
            return 350.00;
        }
        return 350.00;
    }

    getWindowPrice(w?): number {
        if (w) {
            console.log("stop");
        }
        if (!this.doorDesign) return 0.00;
        if (!this.insulation) return 0.00;
        if (!this.width || !this.width.feet) return 0.00;
        let possibleGlassTypes = this.getGlassTypesObject(this.insulation, this.window);
        let clearGlassType = possibleGlassTypes[this.CLEAR_MARKUP];
        let decratrimGlassType = possibleGlassTypes[this.DECRATRIM_MARKUP];
        let window = w ? w : this.window;
        if (!window) return 0.00
        let glassType = this.glassType ? this.glassType : possibleGlassTypes[window.markupType];
        if (window.glassTypes && window.glassTypes.length > 0 && !this.glassType) {
            glassType = window.glassTypes.find(win => win.code === this.CLEAR_MARKUP);
        }
        let width = this.width.feet;
        if (this.width.inches) width++;

        let price = window.price ? window.price : 0.00;
        if (window.isTotalSlotsPricing) {
            price = this.getTotalSlotsPricing(window.price);
            if (window.insulatedSelected) {
                price += this.getTotalSlotsPricing(window.insulatedPrice);
            }
            return price;
        }
        if (window.widthBasedPricing && window.widthBasedPricing.length > 0) {
            let potentialWidthBasedPrice = window.widthBasedPricing.filter(wbp => (wbp.minWidth == null || wbp.minWidth <= width) && (wbp.maxWidth == null || wbp.maxWidth >= width));
            if (potentialWidthBasedPrice && potentialWidthBasedPrice.length === 1) {
                price = potentialWidthBasedPrice[0].price;
            }
        }

        if (window.isPricePerLinearFoot) {
            if (!this.height || !this.height.feet) return 0.00;
            price = this.getPricePerLinearFoot(price);
            if (window.insulatedSelected && window.insulatedPrice) {
                price = price + this.getPricePerLinearFoot(window.insulatedPrice);
            }
            glassType = null;
        } else if (window.isPricePerSquareFoot) {
            if (!this.height || !this.height.feet) return 0.00;
            if (!this.width || !this.width.feet) return 0.00;
            price = this.getPricePerSquareFoot(price);
            if (window.insulatedSelected && window.insulatedPrice) {
                price = price + this.getPricePerSquareFoot(window.insulatedPrice);
            }
            glassType = null;
        } else if (window.markupType == this.CLOSED_MARKUP) {
            return 0.00;
        }
        if (glassType) {
            price = parseFloat(this.hasCustomWindows && glassType.customPrice ? glassType.customPrice : glassType.price);

            if (glassType.widthBasedPricing && glassType.widthBasedPricing.length > 0) {
                let potentialWidthBasedPrice = glassType.widthBasedPricing.filter(wbp => (wbp.minWidth == null || wbp.minWidth <= width) && (wbp.maxWidth == null || wbp.maxWidth >= width));
                if (potentialWidthBasedPrice && potentialWidthBasedPrice.length === 1) price += parseFloat(potentialWidthBasedPrice[0].price);
            } else if (window.markupType == this.DECRATRIM_MARKUP) {
                if (glassType.name == this.DECRATRIM_MARKUP) {
                    price = parseFloat(clearGlassType.price);
                }
                price += parseFloat(decratrimGlassType.price);
            }
        }
        // TODO - add a win count so we don't default fill all width slots
        //let prevIsLongPanel = this.doorDesign.isLongPanel;
        if (typeof window.panelType !== 'undefined' && window.panelType !== null) {
            if (window.panelType === 'Long') {
                this.doorDesign.isLongPanel = true;
            } else {
                this.doorDesign.isLongPanel = false;
            }
        }
        //if (prevIsLongPanel !== this.doorDesign.isLongPanel) {
        //    //let heightSlots = this.getHeightSlots();
        //    //let widthSlots = this.getWidthSlots();
        //    this.setWindowLocations();
        ////    this.windowPositions = Array.from(Array(widthSlots), () => new Array(heightSlots).fill(false));
        //}
        if (this.doorDesign.isLongPanel) price = price * 2;
        if (!window.isPricePerLinearFoot && !window.isPricePerSquareFoot) price = price * this.getNumWindowsFromWindowPositions();
        return price;
    }

    getTotalSlotsPricing(price: number): number {
        let slots = this.getTotalSlots();
        let p = price * slots;
        return p;
    }

    getTotalSlots(): number {
        let h = this.height.feet;
        if (this.height.inches) h++;
        let w = this.width.feet;
        if (this.width.inches) w++;
        let widthSlots = this.getDoorWidthSlots(this.doorDesign);
        let heightSlots = this.getHeightOnlySlots(h);
        if (this.productGroup.identifier === 'ClassicaFullView') {
            widthSlots = this.getClassicaFullViewWidthSlots(w);
            heightSlots = this.getClassicaFullViewHeightOnlySlots(h);
        }
        return widthSlots * heightSlots;
    }

    getWidthAndHeightBasedPricing(pricing: WidthBasedPricing[], width: number): number {
        let price = 0;
        let potentialWidthBasedPrice = pricing.filter(wbp => (wbp.minWidth == null || wbp.minWidth <= width) && (wbp.maxWidth == null || wbp.maxWidth >= width));
        if (potentialWidthBasedPrice && potentialWidthBasedPrice.length === 1) {
            price = potentialWidthBasedPrice[0].price;
        }
        let h = this.height.feet;
        if (this.height.inches) h++;
        let heightSlots = this.getHeightOnlySlots(h);
        if (this.productGroup.identifier === 'ClassicaFullView') {
            heightSlots = this.getClassicaFullViewHeightOnlySlots(h);
        }
        price = price * heightSlots;
        return price;
    }

    getNumWindowsFromWindowPositions(): number {
        if (!this.doorDesign) return 0;
        if (!this.width || !this.width.feet) return 0;
        if (this.hasCustomWindows) {
            let numWindows = 0;
            if (this.windowPositions && this.windowPositions.length > 0 && this.windowPositions[0].length > 0) {
                for (var w = 0; w < this.windowPositions.length; w++) {
                    for (var h = 0; h < this.windowPositions[w].length; h++) {
                        if (this.windowPositions[w][h]) numWindows++;
                    }
                }
            }
            return numWindows;
        }
        let widthSlots = this.getDoorWidthSlots(this.doorDesign);
        return widthSlots;
    }

    getDoorWidthSlots(doorDesign: DoorDesign): number {
        let w = this.width.feet;
        if (this.width.inches) w++;

        let widthSlots = 4;
        //if (doorDesign.name.startsWith('Long')) {
        if (doorDesign.isLongPanel) {
            if (w >= 20) {
                widthSlots = 5;
            } else if (w >= 15) {
                widthSlots = 4;
            } else if (w >= 12) {
                widthSlots = 3;
            } else {
                widthSlots = 2;
            }
        } else {
            if (w >= 20) {
                widthSlots = 10;
            } else if (w >= 16) {
                widthSlots = 8;
            } else if (w >= 12) {
                widthSlots = 6;
            } else if (w == 10) {
                widthSlots = 5;
            } else {
                widthSlots = 4;
            }
        }

        return widthSlots;
    }

    getPricePerLinearFoot(price) {
        if (!this.height || !this.height.feet) return 0.00;
        if (!this.width || !this.width.feet) return 0.00;

        let width = this.width.feet;
        if (this.width.inches && this.width.inches >= 4) {
            if (this.width.feet === 10 || this.width.feet === 12 || this.width.feet === 18) {
                width = this.width.feet + 2;
            } else {
                width++;
            }
        }
        let height = this.height.feet;
        if (this.height.inches) height++;

        price = price * width;
        let heightSlots = this.getHeightOnlySlots(height);
        price = price * heightSlots;
        return price;
    }

    getPricePerSquareFoot(price) {
        if (!this.height || !this.height.feet) return 0.00;
        if (!this.width || !this.width.feet) return 0.00;

        let width = this.width.feet;
        if (this.width.inches && this.width.inches >= 4) {
            if (this.width.feet === 10 || this.width.feet === 12 || this.width.feet === 18) {
                width = this.width.feet + 2;
            } else {
                width++;
            }
        }
        let height = this.height.feet;
        if (this.height.inches) height++;

        price = price * width * height;
        return price;
    }

    getColorPrice(c): number {
        if (!c) return 0.00;
        let price = 0.00
        if (c.name === this.COLOR_ZONE_NAME) {
            if (!this.productGroup) return 0.00;
            let colorZonePrices = this.productGroup.colorZonePricing;
            if (!colorZonePrices) return 0.00;
            let prices = colorZonePrices.filter(czp => (!czp.minWidth || czp.minWidth < this.width.feet) &&
                (!czp.maxWidth || czp.maxWidth >= this.width.feet) &&
                (!czp.minHeight || czp.minHeight < this.height.feet) &&
                (!czp.maxHeight || czp.maxHeight >= this.height.feet) &&
                (!czp.insulationLevel || czp.insulationLevel === this.insulation.insulationLevel.name))
            if (prices.length > 1 || prices.length < 1) return 0.00;
            price = prices[0].price;
        } else if (c.sizeBasedPricing && c.sizeBasedPricing.length > 0) {
            let width = this.width.feet;
            if (this.width.inches) width++;
            let height = this.height.feet;
            if (this.height.inches) height++;
            let prices = c.sizeBasedPricing.filter(czp => (!czp.minWidth || czp.minWidth < width) &&
                (!czp.maxWidth || czp.maxWidth >= width) &&
                (!czp.minHeight || czp.minHeight < height) &&
                (!czp.maxHeight || czp.maxHeight >= height) &&
                (!czp.insulationLevel || czp.insulationLevel === this.insulation.insulationLevel.name))
            if (prices.length > 1 || prices.length < 1) return 0.00;
            price = prices[0].price;
        } else {
            if (!c.price) {
                if (!this.productGroup || !this.productGroup.name || this.productGroup.name === 'Horizon' || this.productGroup.name === 'Vista') return 0.00;
                if (!c.isWhite) return (+this.basePrice * 0.015);
                return 0.00;
            }
            price = c.price;
            if (c.isWoodGrained) {
                let width = this.width.feet;
                if (this.width.inches && this.width.inches >= 4) {
                    if (this.width.feet === 10 || this.width.feet === 12 || this.width.feet === 18) {
                        width = this.width.feet + 2;
                    } else {
                        width++;
                    }
                }
                let height = this.height.feet;
                if (this.height.inches) height++;
                price = price * height * width;
            } else if (!c.isWhite && !c.isColorZone) {
                price = (+this.price * 0.015);
            }
        }
        return this.roundPrice(price);
    }

    roundPrice(p): number {
        return (Math.ceil(p * 100) / 100);
    }

    getGlassTypes(insulationOption?: DoorInsulationOption, window?: DoorWindow): GlassType[] {
        if (!insulationOption) return [];
        if (window && window.glassTypes && window.glassTypes.length > 0) return window.glassTypes;
        let optionsObject = this.doorDesign.insulationOptionsAndColors.find(ioc => ioc.insulationOption._id == insulationOption._id);
        if (optionsObject == null) return [];
        return optionsObject.glassTypes;
    }

    getGlassTypesObject(insulationOption?: DoorInsulationOption, window?: DoorWindow): Object {
        let gtObj = {};
        let glassTypes = this.getGlassTypes(insulationOption, window);
        if (glassTypes) {
            for (var i = 0; i < glassTypes.length; i++) {
                let gt = glassTypes[i];
                if (!gt.isInsulated) gtObj[gt.name] = gt;
            }
        }
        return gtObj;
    }

    showClassicaWarning(): boolean {
        if (!this.height) return false;
        if (!this.productGroup) return false;
        let heightInches = (this.height.feet * 12);
        if (this.height.inches) {
            heightInches += this.height.inches;
        }
        let nearestHeight = this.getNearestClassicaImageHeight();
        if (this.productGroup.name === 'Classica' && (nearestHeight !== heightInches)) return true;
        return false;
    }

    getUnevenWidthPriceByHeightSlots(): number {
        if (this.width && this.width.inches && this.width.inches >= 4) {
            // calculate total panel height
            // add $50 per panel
            let heightSlots = 4;
            let h = this.height.feet;
            if (this.height.inches) h++;
            if (h >= 14) {
                heightSlots = 8;
            } else if (h >= 12) {
                heightSlots = 7;
            } else if (h > 8) {
                heightSlots = 6;
            } else if (h > 7) {
                heightSlots = 5;
            } else {
                heightSlots = 4;
            }

            // determine price per panel
            let panelCharge = 0;
            if (this.productGroup.name === 'Classica') panelCharge = 75;
            else if (this.productGroup.identifier === 'Designer Choice' || this.productGroup.identifier === 'Heritage Carriage' || this.productGroup.identifier === 'Hillcrest') panelCharge = 60;
            else if (this.productGroup.style.displayName === 'Traditional') panelCharge = 55;

            return panelCharge * heightSlots;
        }
        return 0;
    }

}