﻿import { Component } from '@angular/core';
import { DBStateComponent } from 'src/app/build-a-door/shared/components/db-state.component';
import { DBFooterService } from 'src/app/shared/services/db-footer.service';
import { Router } from '@angular/router';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { DoorDesign, DoorWindow, GlassType } from 'src/app/shared/models';
import { SlideInOutAnimation } from 'src/app/shared/styles/animations/slide-in-out-animation';

@Component({
    selector: 'app-select-window',
    templateUrl: './window.component.html',
    styleUrls: ['./window.component.css'],
    animations: [SlideInOutAnimation]
})
export class DBDesignWindowComponent extends DBStateComponent {
    private windows: DoorWindow[];
    private glassTypes: GlassType[];
    private allGlassTypes: GlassType[];
    private animationState: string = 'out';
    private insulationAnimationState: string = 'out';
    private selectedInsulation: string = 'no';
    private isMosaicChecked: boolean = false;
    private isCustomChecked: boolean = false;
    private customWindowSelection: string;

    async ngOnInit() {
        super.verifyState();

        this.dbService.isCustomWindows = this.isCustomChecked;
        this.dbService.customerDesignedDoor.hasCustomWindows = this.isCustomChecked || this.isMosaicChecked;

        let noGlassTypeInsulation = true;
        let noInsulatedSelected = true;

        if (this.dbService.customerDesignedDoor.doorDesign) {
            this.initializeAvailableWindowsAndGlassTypes();
            this.setCollectionWrapperStyling(this.windows);

            if (this.isMosaicChecked) {
                if (this.customWindowSelection === 'left') {
                    this.dbService.customerDesignedDoor.setWindowLocations(this.dbService.customerDesignedDoor.LEFT_MOSAIC);
                } else if (this.customWindowSelection === 'right') {
                    this.dbService.customerDesignedDoor.setWindowLocations(this.dbService.customerDesignedDoor.RIGHT_MOSAIC);
                }
            }

            if (this.dbService.customerDesignedDoor.glassType) {
                this.animationState = 'in';
                let gt = this.findInsulatedParent(this.dbService.customerDesignedDoor.glassType);
                if (gt) {
                    this.insulationAnimationState = 'in';
                    noGlassTypeInsulation = false;
                    if (gt.insulatedVersion == this.dbService.customerDesignedDoor.glassType) {
                        this.selectedInsulation = 'yes';
                    } else {
                        this.selectedInsulation = 'no';
                    }
                } else if (this.dbService.customerDesignedDoor.glassType.insulatedVersion) {
                    noGlassTypeInsulation = false;
                    this.insulationAnimationState = 'in';
                    this.selectedInsulation = 'no';
                }
            } else {
                this.animationState = 'out';
            }
            if (this.dbService.customerDesignedDoor.window && this.dbService.customerDesignedDoor.window.insulatedSelected) {
                this.selectedInsulation = 'yes'
                this.insulationAnimationState = 'in';
                noInsulatedSelected = false;
            }

            if (noGlassTypeInsulation && noInsulatedSelected) {
                this.selectedInsulation = 'no'
                this.insulationAnimationState = 'out';
            }
        }
        super.ngOnInit();
    }

    initializeAvailableWindowsAndGlassTypes(): void {
        this.windows = super.sortCollectionByDisplayOrder(this.dbService.customerDesignedDoor.doorDesign.windows);
        if (this.dbService.customerDesignedDoor.window && this.dbService.customerDesignedDoor.window.glassTypes && this.dbService.customerDesignedDoor.window.glassTypes.length > 0) {
            this.glassTypes = this.dbService.customerDesignedDoor.window.glassTypes;
        } else {
            this.glassTypes = this.dbService.customerDesignedDoor.doorDesign.getGlassTypes(this.dbService.customerDesignedDoor.insulation, this.dbService.customerDesignedDoor.window)
                .filter(gt => !gt.isInsulated && gt.code !== this.dbService.customerDesignedDoor.DECRAGLASS_MARKUP && gt.code !== this.dbService.customerDesignedDoor.DECRATRIM_MARKUP);
        }
        this.allGlassTypes = this.glassTypes;
        if (this.dbService.customerDesignedDoor.impactType === this.dbService.IMPACT || this.dbService.customerDesignedDoor.impactType === this.dbService.IMPACT_DADE) {
            this.windows = this.windows.filter(w => w.name === 'Solid' || w.name.indexOf('Closed') === 0);
            this.glassTypes = [];
        }
        if (this.isCustomChecked || this.isMosaicChecked) {
            this.windows = this.windows.filter(w => w.isCustom);
            if (this.dbService.customerDesignedDoor.window && !this.dbService.customerDesignedDoor.window.isCustom) {
                this.dbService.customerDesignedDoor.window = null;
            }
            this.glassTypes = this.glassTypes.filter(gt => gt.customPrice || gt.customPrice === 0);
        }
    }

    getCollectionStyle(index) {
        let style = super.collectionStyle(index);
        if (!this.isMobile()) {
            var colNum = 1 + (index % 2);
            style['grid-column'] = '' + colNum;
        }
        return style;
    }

    getCustomButtonStyle() {
        if (this.isMobile()) {
            return {
                "text-align": "left",
                "margin": "5px 0px 5px 0px"
            };
        }
        return {
            "text-align": "left",
            "margin": "5px 40px 5px 0px"
        }
    }

    getCollectionClass(win) {
        return super.collectionClass(this.dbService.customerDesignedDoor.window, win);
    }

    onClickWindow(win) {
        if (win !== this.dbService.customerDesignedDoor.window) {
            if (this.dbService.customerDesignedDoor.window) {
                this.dbService.customerDesignedDoor.window.insulatedSelected = false;
                this.dbService.customerDesignedDoor.window.glassTypeSelected = false;
            }
            win.insulatedSelected = false;
            this.dbService.customerDesignedDoor.glassType = null;
            let prevIsLongPanel = this.dbService.customerDesignedDoor.doorDesign.isLongPanel;
            if (typeof win.panelType !== 'undefined' && win.panelType !== null) {
                if (win.panelType === 'Long') {
                    this.dbService.customerDesignedDoor.doorDesign.isLongPanel = true;
                } else {
                    this.dbService.customerDesignedDoor.doorDesign.isLongPanel = false;
                }
            }
            if (prevIsLongPanel !== this.dbService.customerDesignedDoor.doorDesign.isLongPanel) {
                this.customWindowSelection = null;
            //    let heightSlots = this.dbService.customerDesignedDoor.getHeightSlots();
            //    let widthSlots = this.dbService.customerDesignedDoor.getWidthSlots();
            //    this.dbService.customerDesignedDoor.setWindowLocations();
            ////    this.dbService.customerDesignedDoor.windowPositions = Array.from(Array(widthSlots), () => new Array(heightSlots).fill(false));
            }
            if (win && win.glassTypes && win.glassTypes.length > 0) {
                this.glassTypes = win.glassTypes;
                this.dbService.customerDesignedDoor.glassType = win.glassTypes.find(w => w.name === this.dbService.customerDesignedDoor.CLEAR_MARKUP);
            } else {
                this.glassTypes = this.dbService.customerDesignedDoor.doorDesign.getGlassTypes(this.dbService.customerDesignedDoor.insulation, this.dbService.customerDesignedDoor.window)
                    .filter(gt => !gt.isInsulated && gt.code !== this.dbService.customerDesignedDoor.DECRAGLASS_MARKUP && gt.code !== this.dbService.customerDesignedDoor.DECRATRIM_MARKUP);
            }
            this.selectedInsulation = 'no';
            this.animationState = 'out';
            this.insulationAnimationState = 'out';
        }
        this.dbService.customerDesignedDoor.window = win;
        if (!this.isCustomChecked) {
            if (this.isMosaicChecked) {
                if (this.customWindowSelection === 'left') {
                    this.dbService.customerDesignedDoor.setWindowLocations(this.dbService.customerDesignedDoor.LEFT_MOSAIC);
                } else if (this.customWindowSelection === 'right') {
                    this.dbService.customerDesignedDoor.setWindowLocations(this.dbService.customerDesignedDoor.RIGHT_MOSAIC);
                }
            } else {
                this.dbService.customerDesignedDoor.setWindowLocations(this.dbService.customerDesignedDoor.STANDARD_WINDOWS);
            }
        }
        if (this.glassTypes && this.glassTypes.length > 0 && (win.markupType == this.dbService.customerDesignedDoor.CLEAR_MARKUP || win.markupType == this.dbService.customerDesignedDoor.DECRATRIM_MARKUP || win.markupType == this.dbService.NONE_MARKUP)) {
            this.animationState = 'in';
            super.scrollToDiv('glassTypeDiv');
            if (this.dbService.customerDesignedDoor.glassType && this.dbService.customerDesignedDoor.glassType.isInsulated) {
                this.insulationAnimationState = 'in';
                super.scrollToDiv('insulatedGlassDiv');
            }
        } else {
            this.animationState = 'out';
            this.insulationAnimationState = 'out';
            this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumDesignStepsComplete());
        }
        if (win.isInsulated) {
            this.insulationAnimationState = 'in';
            super.scrollToDiv('insulatedGlassDiv');
        }
    }

    onClickGlassType(gt) {
        if (gt === this.dbService.customerDesignedDoor.glassType) {
            this.dbService.customerDesignedDoor.glassType = null;
            this.dbService.customerDesignedDoor.window.glassTypeSelected = false;
            this.insulationAnimationState = 'out';
        } else {
            this.dbService.customerDesignedDoor.glassType = gt;
            this.dbService.customerDesignedDoor.window.glassTypeSelected = true;
            this.selectedInsulation = 'no';
            if (gt.insulatedVersion) {
                this.insulationAnimationState = 'in';
                this.scrollToDiv('insulatedGlassDiv');
            } else {
                this.insulationAnimationState = 'out';
            }
        }
        this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumDesignStepsComplete());
    }

    getGlassTypeCollectionStyle(index) {
        let style = this.getCollectionStyle(index);
        style['padding'] = '10px 10px';
        style['min-height'] = '91.2px';
        return style;
    }

    //onCustomClick() {
    //    this.isCustomChecked = !this.isCustomChecked;
    //    this.dbService.isCustomWindows = this.isCustomChecked;
    //    this.dbService.customerDesignedDoor.hasCustomWindows = this.isCustomChecked || this.isMosaicChecked;
    //    this.isMosaicChecked = false;
    //    this.initializeAvailableWindowsAndGlassTypes();
    //    if (!this.isCustomChecked) this.dbService.customerDesignedDoor.setWindowLocations(this.dbService.customerDesignedDoor.STANDARD_WINDOWS);
    //}

    //onMosaicClick() {
    //    this.isMosaicChecked = !this.isMosaicChecked;
    //    this.isCustomChecked = false;
    //    this.dbService.isCustomWindows = false;
    //    this.dbService.customerDesignedDoor.hasCustomWindows = this.isCustomChecked || this.isMosaicChecked;
    //    this.initializeAvailableWindowsAndGlassTypes();
    //    this.dbService.customerDesignedDoor.setWindowLocations(this.isMosaicChecked ? this.dbService.customerDesignedDoor.MOSAIC_WINDOWS : this.dbService.customerDesignedDoor.STANDARD_WINDOWS);
    //}

    getCustomLayoutButtonClass(button) {
        if (button === this.customWindowSelection && (((button === 'left' || button === 'right') && this.isMosaicChecked) || (button === 'custom' && this.isCustomChecked))) {
            return 'Rectangle-Selected';
        }
        return 'Rectangle';
    }

    getCustomButtonTextColor(button) {
        if (button === this.customWindowSelection && (((button === 'left' || button === 'right') && this.isMosaicChecked) || (button === 'custom' && this.isCustomChecked))) {
            return {
                "color": "#27ae60"
            };
        }
        return {
            "color": "#ffffff"
        };
    }

    onCustomLayoutButtonClick(button) {
        if (button === 'left') {
            if (this.customWindowSelection === button) {
                this.isMosaicChecked = !this.isMosaicChecked;
            } else {
                this.isMosaicChecked = true;
            }
            this.isCustomChecked = false;
            this.dbService.isCustomWindows = false;
            this.dbService.customerDesignedDoor.hasCustomWindows = this.isCustomChecked || this.isMosaicChecked;
            this.initializeAvailableWindowsAndGlassTypes();
            this.dbService.customerDesignedDoor.setWindowLocations(this.isMosaicChecked ? this.dbService.customerDesignedDoor.LEFT_MOSAIC : this.dbService.customerDesignedDoor.STANDARD_WINDOWS);
        } else if (button === 'right') {
            if (this.customWindowSelection === button) {
                this.isMosaicChecked = !this.isMosaicChecked;
            } else {
                this.isMosaicChecked = true;
            }
            this.isCustomChecked = false;
            this.dbService.isCustomWindows = false;
            this.dbService.customerDesignedDoor.hasCustomWindows = this.isCustomChecked || this.isMosaicChecked;
            this.initializeAvailableWindowsAndGlassTypes();
            this.dbService.customerDesignedDoor.setWindowLocations(this.isMosaicChecked ? this.dbService.customerDesignedDoor.RIGHT_MOSAIC : this.dbService.customerDesignedDoor.STANDARD_WINDOWS);
        } else {
            if (this.customWindowSelection === button) {
                this.isCustomChecked = !this.isCustomChecked;
            } else {
                this.isCustomChecked = true;
            }
            this.dbService.isCustomWindows = this.isCustomChecked;
            this.dbService.customerDesignedDoor.hasCustomWindows = this.isCustomChecked || this.isMosaicChecked;
            this.isMosaicChecked = false;
            this.initializeAvailableWindowsAndGlassTypes();
            if (!this.isCustomChecked) this.dbService.customerDesignedDoor.setWindowLocations(this.dbService.customerDesignedDoor.STANDARD_WINDOWS);
        }
        this.customWindowSelection = button;
        if (!this.dbService.customerDesignedDoor.window) {
            let defaultWin = this.windows.find(w => w.isDefaultMosaic);
            if (defaultWin) this.onClickWindow(defaultWin);
        }
    }

    getGlassTypeCollectionClass(gt) {
        if (!this.dbService.customerDesignedDoor.glassType || !gt) return 'Collection-Button-Unselected';
        if ((this.dbService.customerDesignedDoor.glassType._id && gt._id && this.dbService.customerDesignedDoor.glassType._id === gt._id) ||
            (this.dbService.customerDesignedDoor.glassType['id'] && gt.id && this.dbService.customerDesignedDoor.glassType['id'] === gt.id) ||
            (this.dbService.customerDesignedDoor.glassType.code === gt.code)) {
            if (this.dbService.customerDesignedDoor.glassType.isInsulated) this.insulationAnimationState = 'in';
            return 'Collection-Button-Selected';
        } else if (this.dbService.customerDesignedDoor.glassType === gt) {
            if (this.dbService.customerDesignedDoor.glassType.isInsulated) this.insulationAnimationState = 'in';
            return 'Collection-Button-Selected';
        } else {
            return 'Collection-Button-Unselected';
        }
    }

    getPrice(win: DoorWindow) {
        return this.dbService.getPriceDisplay(this.dbService.customerDesignedDoor.getWindowPrice(win));
        //let w = win;
        //if (!win.price) {
        //    w = this.dbService.customerDesignedDoor.doorDesign.windows.find(dw => dw._id === win._id);
        //}
        //return super.getPrice(w, this.dbService.hasWindowPricesLoaded);
    }

    getInsulationButtonClass(button) {
        if (button === this.selectedInsulation) return 'Collection-Button-Selected';
        return 'Collection-Button-Unselected';
    }

    insulationButtonClick(button) {
        if (button !== this.selectedInsulation) {
            this.selectedInsulation = button;
            if (button === 'yes') {
                if (this.dbService.customerDesignedDoor.glassType)
                    this.dbService.customerDesignedDoor.glassType = this.dbService.customerDesignedDoor.glassType.insulatedVersion;
                else {
                    this.dbService.customerDesignedDoor.window.insulatedSelected = true;
                }
            } else {
                // find the parent glass type
                let gt = this.findInsulatedParent(this.dbService.customerDesignedDoor.glassType);
                if (gt) this.dbService.customerDesignedDoor.glassType = gt;
                this.dbService.customerDesignedDoor.window.insulatedSelected = false;
            }
        }
    }

    findInsulatedParent(glassType): GlassType {
        for (var i = 0; i < this.glassTypes.length; i++) {
            let gt = this.glassTypes[i];
            if (gt.insulatedVersion && gt.code === glassType.code) {
                return gt;
            }
        }
        return null;
    }

    getMarkupType(window: DoorWindow): string {
        if (window.markupType === 'None') return '';
        return window.markupType;
    }

    getInsulatedPrice(): string {
        if (this.dbService.customerDesignedDoor.window &&
            this.dbService.customerDesignedDoor.window.isTotalSlotsPricing) {
            let insulatedPrice = this.dbService.customerDesignedDoor.getTotalSlotsPricing(this.dbService.customerDesignedDoor.window.insulatedPrice);
            return this.dbService.getDisplayPrice(insulatedPrice);
        } else if (this.dbService.customerDesignedDoor.window && this.dbService.customerDesignedDoor.window.isInsulated) {
            let insulatedPrice = this.dbService.customerDesignedDoor.getPricePerLinearFoot(this.dbService.customerDesignedDoor.window.insulatedPrice);
            insulatedPrice = this.dbService.getDisplayPrice(insulatedPrice);
            //let regularPrice = this.dbService.getPricePerLinearFoot(this.dbService.customerDesignedDoor.window.price);
            //regularPrice = this.dbService.getDisplayPrice(regularPrice);
            //return this.dbService.getDisplayPrice(insulatedPrice - regularPrice);
            return insulatedPrice;
        } 
        if (!this.dbService.customerDesignedDoor.glassType) return '0.00';
        if (this.dbService.customerDesignedDoor.glassType.insulatedVersion)
            return this.dbService.getDisplayPrice(this.dbService.getInsulatedWindowUpgradePrice(this.dbService.customerDesignedDoor.glassType));
        let igt = this.findInsulatedParent(this.dbService.customerDesignedDoor.glassType);
        return this.dbService.getDisplayPrice(this.dbService.getInsulatedWindowUpgradePrice(igt));
    }

    getGlassTypPrice(gt): string {
        return this.dbService.getDisplayPrice(this.dbService.getGlassTypeUpgradePrice(gt));
    }

    isShowGroupLabels() {
        if (this.animationState === 'in') return true;
        return false;
    }

    isShowContinue() {
        if (this.dbService.customerDesignedDoor.window) return true;
        return false;
    }

    onClickContinue() {
        this.dbService.isCustomWindows = false;
        super.onClickContinue(this.dbService.customerDesignedDoor.color, '/door-builder/design/select-color');
    }

    onClickBack() {
        this.dbService.isCustomWindows = false;
        this.dbService.navButtonPressed = true;
       if (this.dbService.isHideInsulation()) {
            if (this.dbService.isHidePanelDesigns()) {
                this.router.navigate(['/door-builder/design/select-model']);
            } else {
                this.router.navigate(['/door-builder/design/panel-design']);
            }
        } else {
            this.router.navigate(['/door-builder/design/select-insulation']);
        }

    }
}